.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Check if the span is wrapping to a new line */
@media (max-width: 600px) {

  /* When the span is below the object */
  .tweet-header-info object {
    flex: 0 0 auto;
    /* Prevent object from growing */
  }

  .tweet-header-info span {
    margin-left: 10;
    /* Default margin for normal situations */
  }

  /* Adjust margin-left when span wraps below the object */
  .tweet-header-info object+span {
    margin-left: 0;
    /* Remove margin if span is below the object */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Game {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.main_div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.left,
.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  /* Make tweets smaller, each taking 45% of the container width */
  margin: 10px;
  /* Add some margin around each tweet */
}

.tweet-wrap {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.tweet-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

body {
  background: #e6ecf0;
  font-family: 'Asap', sans-serif;
  font-family: 'Roboto', sans-serif;
}

img {
  max-width: 48px;
  max-height: 48px;
}

.avator {
  border-radius: 100px;
  width: 48px;
  height: 48px;
  margin-right: 15px;
}

.tweet-wrap {
  max-width: 100%;
  max-height: 100%;
  background: #fff;
  box-sizing: border-box;
  margin: 5px;
  border-radius: 3px;
  padding: 30px;
  border-bottom: 1px solid #e6ecf0;
  border-top: 1px solid #e6ecf0;
}

.tweet-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-start;
  justify-items: start;
  font-size: 14px;
  gap: 10px;
}

.tweet-header-info {
  font-weight: bold;
  text-align: left;
}

.tweet-header-info span {
  color: #657786;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
}

.tweet-header-info p {
  font-weight: normal;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000000 !important;
  /* Force it to black */
}

.tweet-img-wrap {
  padding-left: 60px;
}

.tweet-info-counts {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-left: 65px;
  margin-top: 1px;
}

.tweet-info-counts div {
  display: flex;
  color: #9c9c9c;
  margin-right: 20px;
}

.tweet-info-counts div svg {
  color: #657786;
  margin-right: 10px;
}


.hover-outline {
  padding: 20px;
  border: 2px solid transparent;
  background-color: #f0f0f0;
  transition: all 0. 3s ease-in-out;
}

.hover-outline:hover {
  border-color: #007BFF;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  transform: scale(1.05);
  /* Optional: slightly enlarge the div */
}

.counter-container {
  position: fixed;
  /* Fix the position relative to the viewport */
  top: 20px;
  /* 20px from the top */
  left: 50%;
  /* Start at the horizontal center */
  transform: translateX(-50%);
  /* Center the div horizontally */
  padding: 15px 30px;
  background-color: #ffffff;
  border: 2px solid #007BFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: Arial, sans-serif;
  z-index: 1000;
  /* Ensure it stays on top */
}

.counter-value {
  font-size: 24px;
  font-weight: bold;
  color: #007BFF;
}

.counter-buttons button {
  padding: 8px 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.counter-buttons button:hover {
  background-color: #0056b3;
}

.button.unselectable.flip-card {
  user-select: none;
  /* Prevents text selection */
  cursor: pointer;
  /* Changes cursor to pointer (indicating it's clickable) */
}

.tweets-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tweets-container-desktop {
  display: flex;
  justify-content: space-around;
  /* Spread tweets evenly */
  padding: 20px;
  /* Add padding around the tweet container */
}

/* Position the text just below the counter */
.center-text {
  position: absolute;
  top: 100px; /* Adjust this value to set the distance below the counter */
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Adjust color if needed */
}

.shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(-5px);
  }

  50% {
    transform: translate(5px);
  }

  75% {
    transform: translate(-5px);
  }

  100% {
    transform: translate(0);
  }
}

.highlight-green {
  background-color: lightgreen;
  /* Light green for correct guesses */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.highlight-red {
  background-color: lightcoral;
  /* Light red for wrong guesses */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.info-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: #555;
}

.info-icon {
  background-color: #f1f1f1;
  border-radius: 50%;
  padding: 8px;
  text-align: center;
  width: 30px;
  height: 30px;
  display: inline-block;
  font-weight: bold;
  color: #333;
}

.info-tooltip {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 40px;
  right: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-icon-container:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}

.info-tooltip a {
  color: #1DA1F2;
  text-decoration: none;
}

.info-tooltip a:hover {
  text-decoration: underline;
}
